import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// import router from './router'
import router from './router/Router.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/development.css';
//import './assets/css/nav.css';

import './assets/css/gallery.css';
import './assets/css/gallery_custom.css';

//import './assets/css/normalize.css'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';

//import VueGoogleMaps from '@fawmi/vue-google-maps';

import * as VueGoogleMaps from 'vue2-google-maps';




import  "./interceptor.js";

import VueCarousel from 'vue-carousel';
 
Vue.use(VueCarousel);

import Scrollspy from 'vue2-scrollspy';
Vue.use(Scrollspy);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.use(LottieVuePlayer);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDUmjLxl_qEb35uInhGq4ncy_a5pz2YYxA', // Replace with your API key
    libraries: 'places', // If using additional libraries like Places API
  },
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
